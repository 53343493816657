var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"analysis"}},[_c('FormulateForm',{staticClass:"analysis-form",on:{"submit":_vm.submitForm}},[_c('b-container',[_c('b-row',[_c('Title',{staticClass:"col-12",attrs:{"title":_vm.isAdd ? _vm.$t('title_add_analysis') : _vm.analysis.analysis_name}}),_c('FormulateInput',{staticClass:"col-md-12 col-12 py-3",attrs:{"type":"text","label":_vm.$t('form_analysis_name'),"name":_vm.$t('form_analysis_name'),"validation":"required","placeholder":_vm.$t('form_analysis_name')},model:{value:(_vm.analysis.analysis_name),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_name", $$v)},expression:"analysis.analysis_name"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3",attrs:{"type":"text","label":_vm.$t('form_analysis_nabm'),"name":_vm.$t('form_analysis_nabm'),"validation":"required","placeholder":_vm.$t('form_analysis_nabm')},model:{value:(_vm.analysis.analysis_nabm),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_nabm", $$v)},expression:"analysis.analysis_nabm"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3",attrs:{"type":"text","label":_vm.$t('form_analysis_price'),"name":_vm.$t('form_analysis_price'),"validation":"required","placeholder":_vm.$t('form_analysis_price')},model:{value:(_vm.analysis.analysis_b_price),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_b_price", $$v)},expression:"analysis.analysis_b_price"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3 pb-4",attrs:{"label":_vm.$t('form_matrix'),"name":_vm.$t('form_matrix'),"validation":"required","type":"vue-select","options":[
            { value: 'Autre', label: _vm.$t('form_matrix_other') },
            { value: 'Cutanées', label: _vm.$t('form_matrix_skin') },
            { value: 'Naso-pharyngé', label: _vm.$t('form_matrix_naso') },
            { value: 'Plasma', label: _vm.$t('form_matrix_plasma') },
            { value: 'P Urethral', label: _vm.$t('form_matrix_p_urethral') },
            { value: 'PV', label: _vm.$t('form_matrix_pv') },
            { value: 'Salive', label: _vm.$t('form_matrix_salive') },
            { value: 'Sang', label: _vm.$t('form_matrix_sang') },
            { value: 'Sang Total', label: _vm.$t('form_matrix_sang_total') },
            { value: 'Selles', label: _vm.$t('form_matrix_faeces') },
            { value: 'Sérum', label: _vm.$t('form_matrix_serum') },
            { value: 'Urines', label: _vm.$t('form_matrix_urine') },
            { value: 'Anal', label: _vm.$t('form_matrix_anal') },
            { value: 'Gorge', label: _vm.$t('form_matrix_gorge') } ]},model:{value:(_vm.analysis.analysis_matrix),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_matrix", $$v)},expression:"analysis.analysis_matrix"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3",attrs:{"label":_vm.$t('form_sampletype'),"validation-name":_vm.$t('form_sampletype'),"validation":"","type":"vue-select","options":[
            {
              value: 'Bleu',
              label: _vm.$t('form_sampletype_blue'),
            },
            {
              value: 'Ecouvillon',
              label: _vm.$t('form_sampletype_ecouvillon'),
            },
            { value: 'Flacon', label: _vm.$t('form_sampletype_flacon') },
            { value: 'Flacon Pus', label: _vm.$t('form_sampletype_flacon_pus') },
            {
              value: 'Gris',
              label: _vm.$t('form_sampletype_grey'),
            },
            {
              value: 'Milieu de transport',
              label: _vm.$t('form_sampletype_transport'),
            },
            {
              value: 'Neoteryx',
              label: _vm.$t('form_sampletype_neoteryx'),
            },
            {
              value: 'Noir',
              label: _vm.$t('form_sampletype_black'),
            },
            { value: 'PV', label: _vm.$t('form_sampletype_pv') },
            {
              value: 'Rouge',
              label: _vm.$t('form_sampletype_red'),
            },
            {
              value: 'Tube avec gel',
              label: _vm.$t('form_sampletype_gel'),
            },
            { value: 'Tube EDTA', label: _vm.$t('form_sampletype_edta') },
            {
              value: 'Tube hépariné',
              label: _vm.$t('form_sampletype_heparine'),
            },
            {
              value: 'Violet',
              label: _vm.$t('form_sampletype_purple'),
            },
            {
              value: 'Selle',
              label: _vm.$t('form_sampletype_stool'),
            },
            {
              value: 'Tube rose',
              label: _vm.$t('form_sampletype_pink'),
            },
            {
              value: 'Anal',
              label: _vm.$t('form_sampletype_anal'),
            } ]},model:{value:(_vm.analysis.analysis_sample_type),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_sample_type", $$v)},expression:"analysis.analysis_sample_type"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3 pb-4",attrs:{"label":_vm.$t('form_allocation'),"name":_vm.$t('form_allocation'),"validation":"required","type":"vue-select","options":[
            { value: '2', label: '2 % (1/50)' },
            { value: '5', label: '5 % (1/20)' },
            { value: '10', label: '10 % (1/10)' },
            { value: '20', label: '20 % (1/5)' },
            { value: '50', label: '50 % (1/2)' },
            { value: '100', label: '100 % (1/1)' },
            { value: '200', label: '200 % (2/1)' },
            { value: '300', label: '300 % (3/1)' } ]},model:{value:(_vm.analysis.analysis_allocation),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_allocation", $$v)},expression:"analysis.analysis_allocation"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3 pb-4",attrs:{"label":_vm.$t('form_molecule_machine'),"name":_vm.$t('form_molecule_machine'),"validation":"required","type":"vue-select","options":[
            { value: 'PENTRA XLR', label: _vm.$t('form_pentraxlr') },
            { value: 'PENTRA C400', label: _vm.$t('form_pentrac400') },
            { value: 'G800', label: _vm.$t('form_g800') },
            { value: 'ENVOI', label: _vm.$t('form_envoi') },
            { value: 'MANUAL', label: _vm.$t('form_manual') },
            { value: 'GCMS', label: _vm.$t('form_gcms') },
            { value: 'LC-TQ', label: _vm.$t('form_lctq') },
            { value: 'LC-QTOF', label: _vm.$t('form_lcqtof') },
            { value: 'QS5', label: _vm.$t('form_qs5') },
            { value: 'IRTF', label: _vm.$t('form_irtf') },
            { value: 'ICPMS', label: _vm.$t('form_icpms') },
            { value: 'AIA 2000', label: _vm.$t('form_aia2000') },
            { value: 'ACCESS', label: _vm.$t('form_access') },
            { value: 'MINICAP', label: _vm.$t('form_minicap') },
            {
              value: 'RT-PCR Ampliquick Biosynex 2 gènes (E, RdRp)',
              label: _vm.$t('form_rtpcr'),
            } ]},model:{value:(_vm.analysis.analysis_machine),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_machine", $$v)},expression:"analysis.analysis_machine"}}),_c('FormulateInput',{staticClass:"col-md-6 col-12 py-3 pb-4",attrs:{"label":_vm.$t('form_analysis_visibility'),"name":_vm.$t('form_analysis_visibility'),"validation":"required","type":"vue-select","options":[
            { value: 'true', label: _vm.$t('form_analysis_visible') },
            { value: 'false', label: _vm.$t('form_analysis_hidden') } ]},model:{value:(_vm.analysis.analysis_visible),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_visible", $$v)},expression:"analysis.analysis_visible"}}),_c('FormulateInput',{staticClass:"col-md-12 col-12 py-3 pb-4",attrs:{"label":_vm.$t('form_analysis_molecules'),"name":_vm.$t('form_analysis_molecules'),"validation":"required","type":"vue-select","options":_vm.molecules,"multiple":""},model:{value:(_vm.analysis.analysis_molecules),callback:function ($$v) {_vm.$set(_vm.analysis, "analysis_molecules", $$v)},expression:"analysis.analysis_molecules"}}),_c('FormulateInput',{staticClass:"col-12 col-md-8 offset-md-2 py-5 text-center submit-btn",attrs:{"type":"submit","label":_vm.$t(_vm.isAdd ? 'form_add_analysis' : 'form_update_analysis')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }